<template>
  <div class="timer" :data-alert="count.alert">{{ count.timer }}</div>
</template>

<script>
export default {
  name: "timer",
  data() {
    return {
      count: null
    }
  },
  props: [ 'at' ],
  created() {
    let _this = this
    this.expire_timer_interval()
    setInterval(() => {
      _this.expire_timer_interval()
    }, 1000)
  },
  methods: {
    expire_timer_interval() {
      
      let duration = this.$dayjs.utc(this.$dayjs().diff(this.$dayjs(this.at)))

      let r
      if(duration.format('m') <= 0)
        r = { timer: duration.format('mm:ss'), alert: false }
      else if(duration.format('H') <= 0)
        r = { timer: duration.format('mm:ss'), alert: true }
      else
        r = { timer: duration.format('HH:mm:ss'), alert: true }

      this.count = r
    }
  }
}
</script>

<style scoped>

</style>